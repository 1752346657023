.grid-item img {
   width: 100% !important;
   height: auto !important;
}

.grid-sizer,
.grid-item {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 10px;
  width: 25%;
}

@media (max-width: 1000px) {
  .grid-sizer,
  .grid-item {
    width: 33%;
  }
}

@media (max-width: 700px) {
  .grid-sizer,
  .grid-item {
    width: 50%;
  }
}

@media (max-width: 400px) {
  .grid-sizer,
  .grid-item {
    width: 100%;
  }
}

.lg-backdrop.in {
  opacity: 0.8 !important;
}

.lg-container *:not(.lg-backdrop.in) {
  background-color: transparent;
}

.loader-ellips {
  font-size: 20px;
  position: relative;
  width: 4em;
  height: 1em;
  margin: 10px auto;
}

.loader-ellips__dot {
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  background: #333;
  position: absolute;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.loader-ellips__dot--1,
.loader-ellips__dot--2 {
  left: 0;
}
.loader-ellips__dot--3 { left: 1.5em; }
.loader-ellips__dot--4 { left: 3em; }

@keyframes reveal {
  from { transform: scale(0.001); }
  to { transform: scale(1); }
}

@keyframes slide {
  to { transform: translateX(1.5em) }
}

.loader-ellips__dot--1 {
  animation-name: reveal;
}

.loader-ellips__dot--2,
.loader-ellips__dot--3 {
  animation-name: slide;
}

.loader-ellips__dot--4 {
  animation-name: reveal;
  animation-direction: reverse;
}

.grid-item {
  position: relative;
}

.image-container {
  position: relative;
  display: block;
  overflow: hidden;
}

.image {
  width: 100%;
  height: auto;
  display: block;
  transition: filter 0.3s ease;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Adjust the alpha value for darkness */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.overlay-text {
  color: #fff; /* Text color */
}

.image-container:hover .image {
  filter: brightness(0.8); /* Adjust brightness value for darkening effect */
}

.image-container:hover .overlay {
  opacity: 1;
}
