.site-header {
  min-height: 70px;
  width: $container-width;
  position: fixed;
  background-color: white;
  box-shadow: 0 5px 6px -6px #bbb;
  z-index: 100;
}
.site-header a {
  text-decoration: none;
}
.site-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 70px;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 20px;
  float: left;
}
.site-subtitle {
  font-size: 20px;
  padding-left: 10px;
}
.menu-list {
  font-size: 20px;
  line-height: $header-thickness;
  float: right;
  padding-right: 15px;
}
.menu-link {
  padding: 7px;
}

/*
  Responsiveness with a dropdown menu
*/

@media (min-width: $small-width) {
  .dropbtn {
    display: none;
  }
  .dropdown-content {
    display: none;
  }
}
@media (max-width: $small-width) {
  .menu-list {
    display: none;
  }
  .site-title {
    padding-right: 0.25rem;
  }
  .dropbtn {
    background-color: white;
    color: black;
    padding: 16px;
    margin-top: 4px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }
  .dropdown {
    position: relative;
    display: inline-block;
    float:right;
    padding-right: 5px;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    right: 0;
  }
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  .dropdown-content a:hover {
    background-color: #f1f1f1
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }
  .dropdown:hover .dropbtn {
    background-color: #f1f1f1;
  }
}
@media (max-width: $very-small-width) {
  .site-title {
    font-size: 20px;
  }
  .site-subtitle {
    font-size: 14px;
  }
}
@media (max-width: $ultra-small-width) {
  .site-subtitle {
    display: none;
  }
}
