.footer {
  background: white;
  color: $brand-color;
  text-align: center;
  min-height: 56px;
  line-height: 45px;
  width: $container-width;
}
.footer a {
  color: $brand-color;
  padding: 0px 10px 0px;
  text-decoration: none;
}
@media (max-width: $container-width) {
  .footer {
    background: white;
    color: $brand-color;
    text-align: center;
    min-height: 56px;
    line-height: 45px;
    width: 95vw;
  }
}
.footer-description {
  display: block;
  margin-bottom: 1rem;
  color: #9a9a9a;
  font-size:0.8rem;
}
